.iphone {
  position: relative;
  margin: auto;
  height: 650px;
  width: 300px;
  border-radius: 32px;
  box-shadow: var(--neumorphic-box-shadow);
  padding: 8px;

  .iphone-notch {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    width: 160px;
    margin: auto;
    background-color: var(--ion-color-white);
    height: 16px;
    border-radius: 0 0 16px 16px;
  }

  .iphone-screen {
    height: 100%;
    width: 100%;
    background-color: black;
    border-radius: 24px;

  }

}

.ipad {
  position: relative;
  margin: auto;
  width: 95%;
  // height: 300px;
  aspect-ratio: 4/3;
  border-radius: 32px;
  box-shadow: var(--neumorphic-box-shadow);
  padding: 8px;

  .iphone-notch {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    width: 160px;
    margin: auto;
    background-color: var(--ion-color-white);
    height: 16px;
    border-radius: 0 0 16px 16px;
    z-index: 1000;
  }

  .iphone-screen {
    height: 100%;
    width: 100%;
    background-color: black;
    border-radius: 16px;

  }

}


.full-screen-modal {
  --width: 100%;
  --height: 98%;
  --border-radius: 0;
  padding-top: 2%;
}

.large-modal {
  @media screen and (min-width: 768px) {
    --width: 80%;
    --height: 80%;
  }
}



.neumorphic-textarea {
  border-radius: 16px;
  box-shadow: var(--neumorphic-box-shadow-inset);
  padding: 8px 16px;
  margin-top: 16px;
  &.inverted {
    box-shadow: var(--neumorphic-box-shadow);
  }
}

ion-item{
  border-color: red;
}

@media screen and (max-width: 768px) {
  .graph-container{
    min-height: 800px;
  }
  
}

.graph-container{
  min-height: 300px;
}





