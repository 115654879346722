/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
// @import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'bootstrap/dist/css/bootstrap-grid.min.css';
@import "~bootstrap-icons/font/bootstrap-icons.css";


@import "swiper/scss";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";
@import "swiper/scss/autoplay";


//font
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');






/// structure import
/// 
///
/// 
/**
 * Convert a font size to a dynamic font size.
 * Fonts that participate in Dynamic Type should use
 * dynamic font sizes.
 * @param size - The initial font size including the unit (i.e. px or pt)
 * @param unit (optional) - The unit to convert to. Use this if you want to
 * convert to a unit other than $baselineUnit.
 */
/**
 * Convert a font size to a dynamic font size but impose
 * a maximum font size.
 * @param size - The initial font size including the unit (i.e. px or pt)
 * @param maxScale - The maximum scale of the font (i.e. 2.5 for a maximum 250% scale).
 * @param unit (optional) - The unit to convert the initial font size to. Use this if you want to
 * convert to a unit other than $baselineUnit.
 */
/**
 * Convert a font size to a dynamic font size but impose
 * a minimum font size.
 * @param size - The initial font size including the unit (i.e. px or pt)
 * @param minScale - The minimum scale of the font (i.e. 0.8 for a minimum 80% scale).
 * @param unit (optional) - The unit to convert the initial font size to. Use this if you want to
 * convert to a unit other than $baselineUnit.
 */
/**
 * Convert a font size to a dynamic font size but impose
 * maximum and minimum font sizes.
 * @param size - The initial font size including the unit (i.e. px or pt)
 * @param minScale - The minimum scale of the font (i.e. 0.8 for a minimum 80% scale).
 * @param maxScale - The maximum scale of the font (i.e. 2.5 for a maximum 250% scale).
 * @param unit (optional) - The unit to convert the initial font size to. Use this if you want to
 * convert to a unit other than $baselineUnit.
 */
/**
 * A heuristic that applies CSS to tablet
 * viewports.
 *
 * Usage:
 * @include tablet-viewport() {
 *   :host {
 *     background-color: green;
 *   }
 * }
 */
/**
 * A heuristic that applies CSS to mobile
 * viewports (i.e. phones, not tablets).
 *
 * Usage:
 * @include mobile-viewport() {
 *   :host {
 *     background-color: blue;
 *   }
 * }
 */
/**
 * A heuristic that applies CSS to tablet
 * viewports.
 *
 * Usage:
 * @include tablet-viewport() {
 *   :host {
 *     background-color: green;
 *   }
 * }
 */
/**
 * A heuristic that applies CSS to mobile
 * viewports (i.e. phones, not tablets).
 *
 * Usage:
 * @include mobile-viewport() {
 *   :host {
 *     background-color: blue;
 *   }
 * }
 */
* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

html {
    width: 100%;
    height: 100%;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
    max-width: 100%;
    overflow-x: hidden;

}

html:not(.hydrated) body {
    display: none;
}

html.ion-ce body {
    display: block;
}

html.plt-pwa {
    height: 100vh;
}

body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
// .example {
    
// }

ion-content{
    --background-color: var(--ion-color-light, #ffffff);
    --color: var(--ion-color-dark, #000000);
    --ion-background-color: var(--ion-color-light, #ffffff);
    --ion-font-color: var(--ion-color-dark, #000000);
}

body {
    background-color: var(--ion-color-light, #ffffff);
    color: var(--ion-color-dark, #000000);

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */


    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin:0;
    //   margin-left: 0;
    //   margin-right: 0;
    //   margin-top: 0;
    //   margin-bottom: 0;
    //   padding-left: 0;
    //   padding-right: 0;
    //   padding-top: 0;
    //   padding-bottom: 0;
    //   position: fixed;
    //   width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    //   height: 101vh;
    //   max-height: 100%;
    /**
   * Because body has position: fixed,
   * it should be promoted to its own
   * layer.
   *
   * WebKit does not always promote
   * the body to its own layer on page
   * load in Ionic apps. Once scrolling on
   * ion-content starts, WebKit will promote
   * body. Unfortunately, this causes a re-paint
   * which results in scrolling being halted
   * until the next user gesture.
   *
   * This impacts the Custom Elements build.
   * The lazy loaded build causes the browser to
   * re-paint during hydration which causes WebKit
   * to promote body to its own layer.
   * In the CE Build, this hydration does not
   * happen, so the additional re-paint does not occur.
   */
    //   transform: translateZ(0);
    //   text-rendering: optimizeLegibility;
    //   overflow: hidden;
    //   touch-action: manipulation;
    //   -webkit-user-drag: none;
    //   -ms-content-zooming: none;
    //   word-wrap: break-word;
    //   overscroll-behavior-y: none;
    //   -webkit-text-size-adjust: none;
    //   text-size-adjust: none;
}

/*# sourceMappingURL=structure.css.map */